import { TextField, Button, firebase, Loader } from "@project/shared";
import Link from "next/link";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useCallback } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import Head from "next/head";
import RestrictedRoute from "../../withRestrictedRoute";
import styled from "styled-components";
import * as Sentry from "@sentry/node";

interface LoginType {
  email: string;
  password: string;
}

const Container = styled.div`
  background: #f2f2f2;
  height: 100vh;
  display: flex;
`;
const LoginWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;

  width: 400px;
  padding: 19px 32px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex-direction: column;
  & .heading {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    display: flex;
    align-items: center;
  }
  & .login-text-field {
    & input {
      background: #cccccc !important;
    }
    & label {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 8px;
    }
  }
  & .forget-link {
    text-align: center;
    margin-bottom: 28px;
    & a {
      text-decoration: underline;
      color: rgba(17, 15, 36, 0.4);
    }
  }
`;
const TextFieldWrapper = styled.div`
  margin-top: 35px;
`;
const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  height: 47px;
  margin-bottom: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  border-radius: 2px;
`;
const InputFieldWrapper = styled.div`
  margin-bottom: 25px;
`;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("メールアドレスを正しくご記入ください")
    .required("メールアドレスが必要です。"),
  password: yup.string().required("パスワードが必要です。"),
});

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const handleLoginFormSubmit = () => {
    handleLogin();
  };
  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  });

  const handleLogin = useCallback(async () => {
    setLoading(true);
    try {
      const data = await firebase
        .auth()
        .signInWithEmailAndPassword(
          formik.values.email,
          formik.values.password
        );
      if (!data || !data.user || !firebase.auth().currentUser) {
        return;
      }
    } catch (error) {
      const errorCode = error.code;
      if (errorCode === "auth/user-not-found") {
        message.error(t("Email address or password does not match."));
      } else if (errorCode === "auth/wrong-password") {
        message.error(t("Email address or password does not match."));
      } else if (errorCode === "auth/invalid-email") {
        message.error(t("Email address or password does not match."));
      } else if (errorCode === "auth/too-many-requests") {
        message.error(
          t(
            "The number of login attempts has exceeded the limit. Please reset the password from the administrator or try again after a while."
          )
        );
      } else {
        message.error(t("An error has occurred. Please try again later."));
      }
      Sentry.captureException(error);
    }
    setLoading(false);
  }, [formik.values]);

  return (
    <>
      <Head>
        <title>{t("コーワペッツ") || process.env.NEXT_PUBLIC_PAGE_TITLE}</title>
      </Head>
      <Container>
        <LoginWrapper>
          <h3 className="heading">ログイン</h3>
          <TextFieldWrapper>
            <form onSubmit={formik.handleSubmit}>
              <InputFieldWrapper>
                <TextField
                  name="email"
                  error={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={t("メールアドレス")}
                  className="login-text-field"
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <TextField
                  name="password"
                  type="password"
                  error={formik.touched.password && formik.errors.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={t("パスワード")}
                  className="login-text-field"
                />
              </InputFieldWrapper>
              <StyledButton htmlType="submit" type="primary" admin={true}>
                {!loading ? t("ログイン") : <Loader />}
              </StyledButton>
              <div className="forget-link">
                <Link href={`/forgot-password`} as={`/forgot-password`}>
                  <a>パスワードを忘れた場合</a>
                </Link>
              </div>
            </form>
          </TextFieldWrapper>
        </LoginWrapper>
      </Container>
    </>
  );
};

export default RestrictedRoute(LoginPage);
